/* Existing Styles */

.login-image-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 70%;
  margin-top: -50vh;
  margin-bottom: 0;
  z-index: 0;
}
/* ... (other existing styles) ... */

/* Updated styles for apple-style-container */
.apple-style-container {
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08);
  padding: 20px;
  margin: 10px auto;
  background-color: transparent;
  transition: box-shadow 0.3s ease;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Larger font for challenge text */
.apple-style-container article[data-cy="post"] {
  font-size: 1.5rem;
}

/* Buttons to the right with orange background and spacing */
.apple-style-container button {
  align-self: flex-end;
  background-color: orange;  /* Orange background */
  color: white;  /* Text color */
  margin-left: 5px;  /* Space between buttons */
  padding: 10px 20px;  /* Padding around text */
  border: none;  /* Remove default border */
  border-radius: 5px;  /* Rounded corners */
}

/* Existing Styles for .success-message, .unsuccessful-message etc. */

.success-message,
.unsuccessful-message {
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08);
  padding: 20px;
  margin: 10px 0;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  font-size: 24px;
  z-index: 9999;
}