/* PageFour.css */

.charityList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center; /* Center horizontally */
}

.charityContainer {
  padding: 10px;
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 220px;
  width: 700px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 5px; /* Add rounded corners */
  transition: background-color 0.3s ease-in-out;
}

.charityContainer:hover {
  cursor: pointer;
}

.charityContainer.selected {
  background-color: #f39200; /* Change this color to your desired selected color */
}

.charityLogo {
  width: 85px;
  height: 85px;
  object-fit: contain;
  position: absolute;
  bottom: 20px; /* Adjust top positioning as needed */
  right: 20px; /* Adjust right positioning as needed */
}

.charityImage {
  width: 200px; /* Adjust width to match desired size */
  height: 200px; /* Adjust height to match desired size */
  object-fit: cover; /* Crop and fit image within dimensions */
  border-radius: 5px; /* Add rounded corners */
  margin-right: 10px; /* Add margin to separate image and text */
}

.charityInfo {
  flex: 1;
}

.charityName {
  font-size: 1.2em;
  margin: 0;
  text-align: left;
}

.charityDescription {
  margin: 5px 0;
  text-align: left;
}

.charityWebsite {
  text-align: left;
}

.charityWebsite a {
  text-align: left;
  text-decoration: none; /* Remove underline */
  color: rgb(66, 84, 221); /* Change link color to gray */
}

.transparent-bg {
  background-color: transparent;
  text-align: center;
}


.PageFourNextButton {
  border-style: none;
  width: 100%;
  background-color: orange; /* Making the button orange */
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 30px;
}
