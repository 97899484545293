.navbar {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  display: flex;
  justify-content: space-between; /* Separate logo and buttons */
  align-items: center; /* Align items vertically */
  z-index: 9999; /* High value to ensure navbar is on top */
  position: relative; /* Required for z-index to take effect */
}

.navbar-logo {
  height: 110px;
  padding-left: 30px;
}

.navbar-buttons {
  display: flex;
  justify-content: flex-end;
}

.navbar-button {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 0 5px;
  padding: 20px 30px 20px 50px;
  text-decoration: none;
}

.navbar-button:hover {
  background-color: transparent;
  border: none;
}

.link-button {
  display: inline-block;
  text-align: center;
  color: white;
  text-decoration: none;
 
}