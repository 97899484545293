
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum full height of the viewport */
}
.content {
  flex-grow: 1;  /* Takes up all available space, pushing the footer down */
}

.website-footer {
  background-color: #FFA500;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  margin-top: 0.5rem;
}

.footer-links a {
  color: white;
  margin: 0 1rem;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
