.content-container {
  position: relative;
}

.home-page-container img {
  max-width: 100%;
  max-height: 100%;
}

.how-it-works-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works-image img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  align-items: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay-signup-button {
  margin: 0;
  position: absolute;
  top: 93.5%;
  left: 52.5%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: orange;
  color: black;
  border-radius: 15px;
  padding: 30px 50px;
  border: 15px solid black;
  cursor: pointer;
  font-size: 3em;
  font-weight: bold;
}


