#form {
  margin: 20px;
}

#incentive-amount {
  width: 30px; /* Set your desired width */
  height: 15px;
}

.App {
  text-align: center;
  margin: 200px;
}

.center{
  display: flex;
  justify-content: center;
}
