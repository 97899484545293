.sign-up-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum full height of the viewport */

  background-image: url('../../assets/signUpImage.jpeg');
  background-size: cover; /* To make sure the image covers the full container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
}



.Login-SignUp-Form {
  max-width: 400px;  /* Increased max-width */
  margin: 3cm 0 0.39in 7%;
  padding: 30px;  /* Increased padding */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Modified style for the input fields */
input[type="text"],
input[type="password"] {
  width: 100%;  /* Increased width to 100% */
  padding: 15px 20px;  /* Increased padding */
  margin: 10px 0;  /* Increased margin */
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Modified style for the submit button */
.SignUpInput {
  width: 100%;
  padding: 15px;  /* Increased padding */
  margin-top: 20px;  /* Increased margin-top */
  border: none;
  border-radius: 5px;
  background-color: orange;
  color: #fff;
  font-size: 18px;  /* Increased font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Change button background on hover */
input[type="submit"]:hover {
  background-color: #333;
}

/* Style for the validation error message */
.validation-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
