
::placeholder {
  color: black;
  opacity: 1;
}

.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30vh;
  background-color: transparent;
}

.PageOneFormContainer {
  
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-items: center; 
}



.noBorder {
  border: none;
  padding: 0;
  margin: 0;
}

/* Text input styling */
.textInput {
  font-size: 1rem;
  border-radius: 0.2rem;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}

/* Next button styling */
.nextButton {
  font-size: 1rem;
  border-radius: 0.2rem;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border: none;
  background-color: #f39200;
  color: white;
  cursor: pointer;
}
