.login-image-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 70%;
  margin-top: -50vh;
  margin-bottom: 0;
  z-index: -1;
}

.login-image-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 0;
}

.log-in-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-image: url('../../assets/loginImage.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  flex: 1;
  background-color: transparent; 
}

.login-form {
  margin-left: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

h1#Log\ in {
  text-align: left;
  position: relative;
  z-index: 2;
  color: white;
}

.LoginForm {
  position: relative;
  left: 0;
  margin-left: 0;
}
